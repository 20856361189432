var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { setActive, setAuthModalOpenStatus, setModal, setRegModalOpenStatus } from "../../../../redux/modalReducer";
import { useAppDispatch } from "../../../../redux/store";
import { useFormik } from "formik";
import { authenticationSchema } from "../../../../schemas";
import style from "./ModalAuthForm.module.css";
import { SubmitButton } from "../SubmitButton/SubmitButton";
import { InputForAuthAndReg } from "../InputForAuthAndReg/InputForAuthAndReg";
import google_icon from "../../../../assets/img/form_logo_google.png";
import vk_icon from "../../../../assets/img/form_logo_vk.png";
import yandex_icon from "../../../../assets/img/form_logo_yandex.png";
import { loginUser } from "../../../../redux/authReducer";
import { ModalResetPassword } from "../../../Modal/modalResetPassword/modalResetPassword";
import ReCAPTCHA from "react-google-recaptcha";
import { NEW_URL } from "../../../../api/API";
export var ModalAuthForm = function () {
    var CAPTCHA_V2_SITE_KEY = "6LejuLMjAAAAAFANqaPTbDwxQC7fEQoeKE23aEZi";
    //для каптчи
    var _a = useState(null), captchaToken = _a[0], setCaptchaToken = _a[1]; //в продакшене null. на локалке, пока работаешь, ставить в ""
    var captchaRef = useRef(null);
    var verifyCatpcha = function (token) {
        setCaptchaToken(token);
    };
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
            setCaptchaToken("1");
        }
        else if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
            setCaptchaToken(null);
        }
    }, []);
    var initialValues = {
        email: "",
        password: "",
    };
    var handleToRegistrationModal = function () {
        // сначала закрывается модалка с авторизацией а затем открываается модалка с регистрацией
        dispatch(setAuthModalOpenStatus({ isOpen: false }));
        dispatch(setRegModalOpenStatus({ isOpen: true }));
    };
    var onSubmit = function (values, actions) {
        dispatch(loginUser(values));
        //обновляются данные на главной странице
        dispatch(setAuthModalOpenStatus({ isOpen: false }));
        resetForm();
    };
    var _b = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: authenticationSchema,
    }), values = _b.values, errors = _b.errors, touched = _b.touched, handleBlur = _b.handleBlur, handleChange = _b.handleChange, handleSubmit = _b.handleSubmit, isValid = _b.isValid, resetForm = _b.resetForm;
    var loginGoogle = function () {
        window.open("".concat(NEW_URL, "/pre/api/social/login/google-oauth2/"));
    };
    var liginVk = function () {
        window.open("".concat(NEW_URL, "/pre/api/social/login/vk-oauth2/"));
    };
    var loginYandex = function () {
        window.open("".concat(NEW_URL, "/pre/api/social/login/yandex-oauth2/"));
    };
    var ResetPasswortUseEmail = function (value) {
        dispatch(setActive(false));
        dispatch(setModal(value));
        dispatch(setActive(true));
    };
    return (_jsx("div", __assign({ className: style.modalAuthForm, onClick: function (e) { return e.stopPropagation(); } }, { children: _jsxs("div", __assign({ className: style.modalAuthFormContent }, { children: [_jsxs("div", __assign({ className: style.modalAuthFormTitle }, { children: [_jsx("h2", __assign({ className: style.title }, { children: "\u0412\u0445\u043E\u0434" })), _jsx(ModalResetPassword, {})] })), _jsxs("form", __assign({ className: style.form, autoComplete: "off", onSubmit: handleSubmit }, { children: [_jsxs("div", __assign({ className: style.block_input }, { children: [_jsx(InputForAuthAndReg, { placeholder: "E-mail", handleChange: handleChange, handleBlur: handleBlur, isError: errors.email && touched.email, value: values.email, name: "email", type: "email" }), _jsx(InputForAuthAndReg, { handleChange: handleChange, handleBlur: handleBlur, isError: errors.password && touched.password, value: values.password, placeholder: "Пароль", type: "password", name: "password" })] })), _jsx("div", __assign({ className: style.captcha_block }, { children: _jsx(ReCAPTCHA, { className: style.captcha, ref: captchaRef, onChange: function (token) { return verifyCatpcha(token); }, size: "normal", sitekey: CAPTCHA_V2_SITE_KEY }) })), _jsxs("div", __assign({ className: style.button_after_captcha }, { children: [_jsx(SubmitButton, { name: "Войти", isValid: isValid && (captchaToken === null || captchaToken === void 0 ? void 0 : captchaToken.length) > 0 }), " "] }))] })), _jsxs("div", __assign({ className: style.modalAuthLineOr }, { children: [_jsx("span", { children: "\u00A0" }), _jsx("p", { children: "\u0438\u043B\u0438" }), _jsx("span", { children: "\u00A0" })] })), _jsxs("div", __assign({ className: style.modalAuthSocLinks }, { children: [_jsx("button", __assign({ onClick: function () {
                                loginGoogle();
                            }, className: style.modalAuthSocLink }, { children: _jsx("img", { className: style.imgButton, src: google_icon, alt: "google" }) })), _jsx("button", __assign({ onClick: function () {
                                liginVk();
                            }, className: style.modalAuthSocLink }, { children: _jsx("img", { className: style.imgButton, src: vk_icon, alt: "google" }) })), _jsx("button", __assign({ onClick: function () {
                                loginYandex();
                            }, className: style.modalAuthSocLink }, { children: _jsx("img", { className: style.imgButton, src: yandex_icon, alt: "google" }) }))] })), _jsxs("div", __assign({ className: style.modalAuthRedirects }, { children: [_jsx(Link, __assign({ onClick: function () { return handleToRegistrationModal(); }, className: style.modalAuthRedirectsLink, to: "#" }, { children: "\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F" })), _jsx("div", __assign({ className: style.modalAuthRedirectsLink }, { children: _jsx("button", __assign({ className: style.modalAuthRedirectsLink, onClick: function () {
                                    ResetPasswortUseEmail("refresh-password-use-email");
                                } }, { children: "\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C" })) }))] }))] })) })));
};
