// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gsURzMCmvOop_l9JK4YA {
  cursor: pointer;
  border: none;
  width: 300px;
  height: 40px;
  min-height: 40px;
  background: rgba(20, 130, 197, 0.6);
  border-radius: 8px;
  color: var(--main-white-color);
  font-size: 14px;
  font-weight: 700;
}

.iHw12GQyiWhsn18PoPKf {
  width: 300px;
  min-height: 40px;
  border-radius: 8px;
  opacity: 0.35;
  margin-top: 6px;
}

@media (max-width: 480px) {

  .gsURzMCmvOop_l9JK4YA,
  .iHw12GQyiWhsn18PoPKf {
    min-height: 40px;
    height: 40px;
    width: 100%;

    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-height: 700px) {

  .gsURzMCmvOop_l9JK4YA,
  .iHw12GQyiWhsn18PoPKf {
    width: 300px;
    height: 40px;
    background: rgba(20, 130, 197, 0.6);
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Helpers/Forms/SubmitButton/SubmitButton.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mCAAmC;EACnC,kBAAkB;EAClB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;;EAEE;;IAEE,gBAAgB;IAChB,YAAY;IACZ,WAAW;;IAEX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;;EAEE;;IAEE,YAAY;IACZ,YAAY;IACZ,mCAAmC;IACnC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".button_submitted {\r\n  cursor: pointer;\r\n  border: none;\r\n  width: 300px;\r\n  height: 40px;\r\n  min-height: 40px;\r\n  background: rgba(20, 130, 197, 0.6);\r\n  border-radius: 8px;\r\n  color: var(--main-white-color);\r\n  font-size: 14px;\r\n  font-weight: 700;\r\n}\r\n\r\n.button_unSubmitted {\r\n  width: 300px;\r\n  min-height: 40px;\r\n  border-radius: 8px;\r\n  opacity: 0.35;\r\n  margin-top: 6px;\r\n}\r\n\r\n@media (max-width: 480px) {\r\n\r\n  .button_submitted,\r\n  .button_unSubmitted {\r\n    min-height: 40px;\r\n    height: 40px;\r\n    width: 100%;\r\n\r\n    width: 300px;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n  }\r\n}\r\n\r\n@media (max-height: 700px) {\r\n\r\n  .button_submitted,\r\n  .button_unSubmitted {\r\n    width: 300px;\r\n    height: 40px;\r\n    background: rgba(20, 130, 197, 0.6);\r\n    border-radius: 8px;\r\n    font-weight: 700;\r\n    font-size: 16px;\r\n    line-height: 130%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_submitted": `gsURzMCmvOop_l9JK4YA`,
	"button_unSubmitted": `iHw12GQyiWhsn18PoPKf`
};
export default ___CSS_LOADER_EXPORT___;
