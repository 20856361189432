// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FYKDIU9oK00g2h_iWJsR {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-blur-wrapper);
  backdrop-filter: blur(3px);
  z-index: 998;
  overflow: hidden;
  display: flex;
  align-content: center;
  grid-template-columns: 1fr;
  justify-items: center;
}

@media (max-width: 480px) {
  .FYKDIU9oK00g2h_iWJsR {
    /* position: relative; */
    /* position: absolute; */
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Helpers/ModalBlurWrapper/ModalBlurWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,0BAA0B;EAC1B,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,qBAAqB;EACrB,0BAA0B;EAC1B,qBAAqB;AACvB;;AAEA;EACE;IACE,wBAAwB;IACxB,wBAAwB;EAC1B;AACF","sourcesContent":[".modalBlurWrapper {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: var(--modal-blur-wrapper);\r\n  backdrop-filter: blur(3px);\r\n  z-index: 998;\r\n  overflow: hidden;\r\n  display: flex;\r\n  align-content: center;\r\n  grid-template-columns: 1fr;\r\n  justify-items: center;\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  .modalBlurWrapper {\r\n    /* position: relative; */\r\n    /* position: absolute; */\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBlurWrapper": `FYKDIU9oK00g2h_iWJsR`
};
export default ___CSS_LOADER_EXPORT___;
