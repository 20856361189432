import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMenuTC } from '../../redux/menuReducer';
import { useAppDispatch } from '../../redux/store';
import { Layout } from './Layout';
export function ContainerLayout() {
    var dispatch = useAppDispatch();
    var menu = useSelector(function (state) { return state.menu.results; });
    useEffect(function () {
        dispatch(getMenuTC());
    }, []);
    return (_jsx(Layout, { menu: menu }));
}
;
