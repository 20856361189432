var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense } from "react";
import { useAppDispatch } from "../../../redux/store";
import { NEW_URL } from "../../Card/cardImports";
import LoadablehHeaderAvatar from "../../LoadableImage/LoadablehHeaderAvatar/LoadablehHeaderAvatar";
import { LogOutLittleCard } from "../../LogOutLittleCard/LogOutLittleCard";
//асеты
import chevronDown from "../../../assets/img/chevronDown.png";
import Account_circle from "../../../assets/img/Account_circle.svg";
//стили
import style from "./loginBlock.module.css";
import { setAuthModalOpenStatus } from "../../../redux/modalReducer";
import { PopupWithoutOverlay } from "../../../UIKit/PopupWithoutOverlay/PopupWithoutOverlay";
import { useTranslation } from "react-i18next";
var LoginBlock = function (_a) {
    var _b;
    var isUserAuth = _a.isUserAuth, openLittleMenu = _a.openLittleMenu, setOpenLittleMenu = _a.setOpenLittleMenu, userAuthInfo = _a.userAuthInfo, isAvatarLoading = _a.isAvatarLoading;
    var dispatch = useAppDispatch();
    var t = useTranslation("common").t;
    var setAuthModalOpen = function () {
        if (!isUserAuth) {
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
        }
    };
    var handleCloseLogoutMenu = function () {
        setOpenLittleMenu(false);
    };
    if (isUserAuth) {
        return (_jsxs(Suspense, __assign({ fallback: _jsx(_Fragment, {}) }, { children: [_jsxs("div", __assign({ onClick: function () {
                        setOpenLittleMenu(!openLittleMenu);
                    }, className: style.login }, { children: [_jsx(LoadablehHeaderAvatar, { isLoading: isAvatarLoading, src: "".concat(NEW_URL).concat((_b = userAuthInfo === null || userAuthInfo === void 0 ? void 0 : userAuthInfo.info) === null || _b === void 0 ? void 0 : _b.avatar) }), _jsx("img", { className: style.chevronIconStyle, src: chevronDown, alt: "chevron down" })] })), _jsx(PopupWithoutOverlay, __assign({ onClose: handleCloseLogoutMenu, isOpened: openLittleMenu }, { children: _jsx(LogOutLittleCard, { onClose: function () { return setOpenLittleMenu(false); }, auth_user_info: userAuthInfo }) }))] })));
    }
    if (!isUserAuth) {
        return (_jsx(Suspense, __assign({ fallback: _jsx(_Fragment, {}) }, { children: _jsxs("div", __assign({ onClick: function () { return setAuthModalOpen(); }, className: style.login }, { children: [_jsx("img", { src: Account_circle }), _jsx("span", { children: "\u0412\u043E\u0439\u0442\u0438" })] })) })));
    }
};
export default LoginBlock;
