var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState } from "react";
import style from "./eventsFilterForMobile.module.css";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { setActive } from "../../redux/modalReducer";
import { fetchEventFilterCategoriesAndLocations, setCheckboxChecked, setCheckboxNotChecked, setEventFilterActive, setEventFilterState, setFilterTriggeredOff, } from "../../redux/reducers/eventsPage/eventsReducer";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineCheck } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CalendarMiniEvents from "../calendarMini/CalendarMiniEvents";
import { useMediaQuery } from "react-responsive";
import { useOutsideClick } from "../../hooks/useGetPublicationOnMainPage/useComponentVisible/useClickOutside";
import dayjs from "dayjs";
export var EventsFilterForMobile = function (_a) {
    // const isFilterNeedOff = useSelector<RootState, boolean>(
    //   (state) => state.events.isFilterTriggered
    // );
    var isActive = _a.isActive;
    var dispatch = useAppDispatch();
    var isEventFilterActive = useSelector(function (state) { return state.events.isEventFilterActive; });
    var filterCategories = useSelector(function (state) { return state.events.eventsFilterCategories; });
    var startValues = useSelector(function (state) { return state.events.eventsFilterState; });
    var filterLocations = useSelector(function (state) { return state.events.eventsFilterLocations; });
    var typeSortByRelevant = useSelector(function (state) { return state.events.typeSortByRelevant; });
    var startLocationValue;
    if (startValues.location_id === "1") {
        startLocationValue = "Онлайн";
    }
    if (startValues.location_id === "2") {
        startLocationValue = "Офлайн";
    }
    if (startValues.location_id === "3") {
        startLocationValue = "Онлайн + Офлайн";
    }
    if (!startValues.location_id) {
        startLocationValue = "Ничего не выбрано";
    }
    var _b = useState(startLocationValue), formatSelect = _b[0], setFormatSelect = _b[1];
    var _c = useState(false), formatSelectOpen = _c[0], setFormatSelectOpen = _c[1];
    var formatEventSelectorRef = useRef(null);
    useOutsideClick(formatEventSelectorRef, function () { return setFormatSelectOpen(false); });
    var _d = useState(""), inputValueStart = _d[0], setInputValueStart = _d[1];
    var _e = useState(""), inputValueEnd = _e[0], setInputValueEnd = _e[1];
    var _f = useState(), selectedDayStart = _f[0], setSelectedDayStart = _f[1];
    var _g = useState(), selectedDayEnd = _g[0], setSelectedDayEnd = _g[1];
    var isPlanshet = useMediaQuery({
        query: "(min-width: 1241px)",
    });
    var handleCloseFilter = function () {
        dispatch(setActive(false));
    };
    if (isPlanshet) {
        handleCloseFilter();
    }
    React.useEffect(function () {
        if (!isEventFilterActive) {
            dispatch(fetchEventFilterCategoriesAndLocations());
        }
    }, [isEventFilterActive]);
    if (window.location.pathname !== "/events/") {
        handleCloseFilter();
    }
    var onSubmit = function (values, actions) {
        var _a;
        var ordersByDate = ["datetime_open__range", "datetime_open__gte", "datetime_open__lte"];
        var dateNow = dayjs();
        //интервалы дат приходящие из формы
        var date_from = values.dateFromResult;
        var date_to = values.dateToResult;
        // if (typeSortByRelevant === "present" && dateNow.isAfter(dayjs(date_to))) {
        //   dispatch(setMessageToUserForModal("Диапазон дат должен быть позднее, чем сегодняшняя дата"));
        //   dispatch(setModal("success-alert-modal"));
        //   dispatch(setActive(true));
        //   setTimeout(() => {
        //     dispatch(setActive(false));
        //   }, 10000);
        //   return;
        // } else if (typeSortByRelevant === "past" && (dateNow.isBefore(dayjs(date_from)) || dateNow.isBefore(dayjs(date_to)))) {
        //   dispatch(setMessageToUserForModal("Диапазон дат должен быть раньше, чем сегодняшняя дата"));
        //   dispatch(setModal("success-alert-modal"));
        //   dispatch(setActive(true));
        //   setTimeout(() => {
        //     dispatch(setActive(false));
        //   }, 10000);
        //   return;
        // }
        //фильтер для запроса на бек
        var filter = {};
        //проверка если указаны две даты то отправлять = date_open__range
        if (date_from !== "" && date_to !== "") {
            filter[ordersByDate[0]] = ["".concat(date_from, " 09:25:38+00:00"), "".concat(date_to, " 09:25:38+00:00")];
        }
        //проверка если указаны две даты то отправлять = date_open__gte
        if (date_from !== "" && date_to === "") {
            filter[ordersByDate[1]] = "".concat(date_from);
        }
        //проверка если указаны две даты то отправлять = date_open__lte
        if (date_from === "" && date_to !== "") {
            filter[ordersByDate[2]] = "".concat(date_to);
        }
        if (((_a = values === null || values === void 0 ? void 0 : values.сategory_id__in) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            filter["сategory_id__in"] = values.сategory_id__in;
        }
        filter["page"] = String(values.page);
        if (typeof values.location_id === "string" && values.location_id !== "") {
            filter["location_id"] = values.location_id;
        }
        // dispatch(setFilterEventsPublications(filter));
        // dispatch(setIsNeedUpdateFilteredContent(true));
        dispatch(setEventFilterState(filter));
        dispatch(setEventFilterActive(true));
        dispatch(setActive(false));
    };
    var _h = useFormik({
        initialValues: {
            page: 2,
            dateFromResult: "",
            dateToResult: "",
            location_id: startValues.location_id,
            сategory_id__in: startValues.сategory_id__in,
        },
        onSubmit: onSubmit,
        enableReinitialize: true,
    }), values = _h.values, handleBlur = _h.handleBlur, handleChange = _h.handleChange, handleSubmit = _h.handleSubmit, resetForm = _h.resetForm, setFieldValue = _h.setFieldValue;
    var handleFilterTriggerOff = function () {
        resetForm();
        dispatch(setFilterTriggeredOff());
        dispatch(setCheckboxNotChecked());
        dispatch(setEventFilterState({
            page: 2,
            location_id: "",
            сategory_id__in: [],
        }));
        setFormatSelect("Ничего не выбрано");
        setInputValueStart("");
        setInputValueEnd("");
        setSelectedDayStart(undefined);
        setSelectedDayEnd(undefined);
    };
    var isValidFilter = function (values) {
        var oneOfIsChecked = [];
        Object.values(values).forEach(function (obj) {
            if (obj !== "" && (obj === null || obj === void 0 ? void 0 : obj.length) >= 1) {
                oneOfIsChecked.push(true);
            }
            else {
                oneOfIsChecked.push(false);
            }
        });
        if (Object.keys(values).length >= 1 && oneOfIsChecked.includes(true)) {
            return true;
        }
        else {
            return false;
        }
    };
    return isActive ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: style.filter }, { children: [_jsxs("div", __assign({ className: style.title_and_btnClose }, { children: [_jsx("span", __assign({ className: style.title_filter }, { children: "\u0424\u0438\u043B\u044C\u0442\u0440 \u0441\u043E\u0431\u044B\u0442\u0438\u0439" })), _jsx("button", __assign({ className: style.btnClose, onClick: function () { return handleCloseFilter(); } }, { children: _jsx(CloseIcon, { fontSize: "large", sx: { width: "24px", height: "24px" } }) }))] })), _jsxs("form", __assign({ className: style.form, autoComplete: "off", onSubmit: handleSubmit }, { children: [_jsxs("div", __assign({ className: style.filter__content }, { children: [_jsxs("div", __assign({ className: style.filter__group }, { children: [_jsx("span", __assign({ className: style.filter__parametr }, { children: "\u041C\u0435\u0441\u0442\u043E \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u0438\u044F" })), _jsx("div", __assign({ className: style.select_wrapper }, { children: _jsxs("div", __assign({ className: style.select_wrap, onClick: function () { return setFormatSelectOpen(!formatSelectOpen); } }, { children: [_jsxs("div", __assign({ className: style.select_current_name }, { children: [_jsx("p", { children: formatSelect }), formatSelectOpen ? _jsx(IoIosArrowUp, {}) : _jsx(IoIosArrowDown, {})] })), formatSelectOpen && (_jsx("ul", __assign({ ref: formatEventSelectorRef, className: style.select_list }, { children: formatSelectOpen &&
                                                            (filterLocations === null || filterLocations === void 0 ? void 0 : filterLocations.map(function (item, index) {
                                                                return (_jsx("input", { type: "button", defaultValue: item.name, id: "location_name", className: item.name === formatSelect ? style.select_input__active : style.select_input, name: "location_id", onBlur: handleBlur, onClick: function () {
                                                                        setFormatSelectOpen(false);
                                                                        setFieldValue("location_id", "".concat(item.id));
                                                                        setFormatSelect(item.name);
                                                                    } }, index));
                                                            })) })))] })) }))] })), _jsxs("div", __assign({ className: style.filter__group }, { children: [_jsx("span", __assign({ className: style.filter__parametr }, { children: "\u0414\u0430\u0442\u0430" })), _jsx("div", __assign({ className: style.filter__date }, { children: _jsxs("div", __assign({ className: style.filter__date_wrapper }, { children: [_jsx(CalendarMiniEvents, { width: "127px", isCalendarStart: true, name: "dateFromResult", setFieldValueFormik: setFieldValue, inputValueStart: inputValueStart, setInputValueStart: setInputValueStart, selectedDayStart: selectedDayStart, setSelectedDayStart: setSelectedDayStart }), _jsx(CalendarMiniEvents, { width: "127px", isCalendarStart: false, name: "dateToResult", setFieldValueFormik: setFieldValue, inputValueEnd: inputValueEnd, setInputValueEnd: setInputValueEnd, selectedDayEnd: selectedDayEnd, setSelectedDayEnd: setSelectedDayEnd })] })) }))] })), _jsxs("div", __assign({ className: style.filter__group_topic }, { children: [_jsx("span", __assign({ className: style.filter__parametr }, { children: "\u0422\u0435\u043C\u0430" })), _jsx("div", __assign({ className: style.filter_group_fullCategories }, { children: _jsx("div", __assign({ className: style.scrollableCategories }, { children: filterCategories === null || filterCategories === void 0 ? void 0 : filterCategories.map(function (button) { return (_jsx("div", __assign({ className: style.filter__group_topic__checkbox }, { children: _jsxs("label", __assign({ className: style.filter__input_wrap }, { children: [_jsx("input", { className: style.filter__input_checkbox, onChange: function (e) {
                                                                    dispatch(setCheckboxChecked(button));
                                                                    handleChange(e);
                                                                }, checked: button.check, onBlur: handleBlur, value: button.id, type: "checkbox", name: "\u0441ategory_id__in" }), _jsx("span", __assign({ className: style.filter__input_fake }, { children: _jsx(AiOutlineCheck, { className: style.hidden_icon }) })), _jsx("span", __assign({ className: style.filter__input_text }, { children: button.name }))] })) }), button.id)); }) })) }))] }))] })), _jsxs("div", __assign({ className: style.filter__buttons }, { children: [isValidFilter(values) ? (_jsx("button", __assign({ type: "submit", className: style.filter__buttons_apply }, { children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440" }))) : null, isValidFilter(values) ? (_jsx("button", __assign({ type: "button", onClick: handleFilterTriggerOff, className: style.filter__buttons_clear }, { children: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440" }))) : null] }))] }))] })) })) : null;
};
