// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NGqZlxuJDGvsTP59rlA6 {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: 10px;
}

.NGqZlxuJDGvsTP59rlA6 span {
    font-style: normal;
    font-weight: 480;
    font-size: 90%;
    /* 16px */
    line-height: 32px;
    letter-spacing: 0.1px;
    color: var(--main-black-of-black-color);
    margin-left: 8px;
}

.b2_l3Qvm4kM_eWiPMHp3 {
    width: 10px;
    height: 5px;
    margin-left: 4px;
}

.Pjq2PRBf7_jB0slyk_Rv {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 93.4%;
    background-color: transparent;
    z-index: 998;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    grid-template-columns: 1fr;
    justify-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/helpers/loginBlock.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,SAAS;IACT,iBAAiB;IACjB,qBAAqB;IACrB,uCAAuC;IACvC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,qBAAqB;IACrB,0BAA0B;IAC1B,qBAAqB;AACzB","sourcesContent":[".login {\r\n    display: flex;\r\n    cursor: pointer;\r\n    align-items: center;\r\n    margin-left: 10px;\r\n}\r\n\r\n.login span {\r\n    font-style: normal;\r\n    font-weight: 480;\r\n    font-size: 90%;\r\n    /* 16px */\r\n    line-height: 32px;\r\n    letter-spacing: 0.1px;\r\n    color: var(--main-black-of-black-color);\r\n    margin-left: 8px;\r\n}\r\n\r\n.chevronIconStyle {\r\n    width: 10px;\r\n    height: 5px;\r\n    margin-left: 4px;\r\n}\r\n\r\n.logoutLittleMenuWrapper {\r\n    position: fixed;\r\n    bottom: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 93.4%;\r\n    background-color: transparent;\r\n    z-index: 998;\r\n    overflow: hidden;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    align-content: center;\r\n    grid-template-columns: 1fr;\r\n    justify-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `NGqZlxuJDGvsTP59rlA6`,
	"chevronIconStyle": `b2_l3Qvm4kM_eWiPMHp3`,
	"logoutLittleMenuWrapper": `Pjq2PRBf7_jB0slyk_Rv`
};
export default ___CSS_LOADER_EXPORT___;
