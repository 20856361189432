var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { setActive } from "../../../redux/modalReducer";
import { useAppDispatch } from "../../../redux/store";
import style from "./ModalBlurWrapper.module.css";
export var ModalBlurWrapper = function (_a) {
    var children = _a.children, onClickClose = _a.onClickClose;
    var dispatch = useAppDispatch();
    var handleCloseModal = function () {
        dispatch(setActive(false));
        if (onClickClose) {
            onClickClose(false);
        }
    };
    return (_jsx("section", __assign({ onClick: function (e) {
            handleCloseModal();
        }, className: style.modalBlurWrapper }, { children: children })));
};
