var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./ButtonUp.module.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
export function ButtonUp() {
    var handleUpToTheScreen = function () {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (_jsxs("div", __assign({ className: style.upBtnWrapper }, { children: [_jsx("span", __assign({ onClick: function () { return handleUpToTheScreen(); } }, { children: "\u0412\u0432\u0435\u0440\u0445" })), _jsx(ExpandLessIcon, { onClick: function () { return handleUpToTheScreen(); }, sx: { color: "#050A1482", alignSelf: "center", cursor: "pointer" }, fontSize: "large" })] })));
}
