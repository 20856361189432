var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import style from "./SubmitButton.module.css";
export var SubmitButton = function (_a) {
    var isValid = _a.isValid, name = _a.name;
    return (_jsx("button", __assign({ className: isValid ? style.button_submitted : style.button_unSubmitted, type: "submit", disabled: !isValid }, { children: name })));
};
